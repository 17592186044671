import React, { FC } from 'react'
import { PageProps } from 'gatsby'

import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'

const NotFoundPage: FC<PageProps> = ({ location }) => (
  <Layout location={location}>
    <SEO pageTitle="404: Not Found" />
    <h1>404</h1>
    <p>Diese gewünschte Seite existiert nicht.</p>
  </Layout>
)

export default NotFoundPage
